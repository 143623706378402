@import './_common-variables.sass'

body
  &.is-native, &.is-mobile
    background-color: $html-background-color
    -webkit-touch-callout: none
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    *
      -webkit-tap-highlight-color: rgba(0,0,0,0)
