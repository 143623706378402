@charset "utf-8";
@import './init';

:root {
  --background-header: var(--primary);
  --header-color: #FFFFFF;
}


app-root[class] {
  min-height: 100vh;
  position: relative;
  background: white;
  display: block;
}

.page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 99;

  .page-loader {
    border-left-color: $page-loader-primary-color;
  }

  &.root {
    z-index: -1;
  }

  &.hide {
    display: none;
  }

}

a {
  text-decoration: none;
}

form {
  display: inline;
}

.input-title {
  font-family: var(--font-family-semiBold);
  font-size: calculateRem(14px);
  color: var(--black-space);
}

lay-title {
  p {
    color: $black !important;
    font-size: 1.125rem;
  }
}

.is-primary, .is-primary:active, .is-primary:hover {
  background-color: var(--primary);
}

el-yes-no {
  #yes-button:checked + label, #no-button:checked + label, label.button:active {
    border-color: var(--primary);
    color: var(--primary);
  }
}

.button.is-primary-button[disabled] {
  background-color: var(--primary);
}
