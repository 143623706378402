lay-main[dir=rtl]

    app-icon.is-spaced-right,
    app-image figure.is-spaced-right
        margin-right: 0
        margin-left: $space-icon

    .lay-link
        [name='chevron-navigation'] .icon
            transform: rotate(180deg)

    lay-panel-block .is-align-end
            margin-left: 0
            margin-right: auto

    .control
        &.has-icons-left
            .input,
            .select select
                padding-left: 0
                padding-right: 1.75rem
            .icon.is-left
                left: auto
                right: 0

        &.has-icons-right
            .input,
            .select select
                padding-right: 0
                padding-left: 1.75rem
            .icon.is-right
                right: auto
                left: 0

    el-input-text, el-input-password
        input
            margin-left: 1.5rem

    * + el-email-badge, el-email-badge + *
        margin-left: 0
        margin-right: $badge-gutter

    app-switch.switch-container
        .switch
            transform: translateX(22px)

        &.is-active .switch
            transform: translateX(0)

    .is-extra-icon, .has-search
        right: auto !important
        left: 1.5rem !important

    .icon-log-out-icon,
    .icon-chevron-left,
    .icon-chevron-right,
    &:before
        -webkit-transform: scaleX(-1)
        transform: scaleX(-1)

    app-input-number
        input.has-text-right
            text-align: left !important

    el-plus-minus
        .column:not(.button-icon)
            direction: ltr
        .column.button-icon
            direction: ltr
            text-align: left !important

    .temperature-wrapper
        direction: ltr
