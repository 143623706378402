[data-app="vaillant"]:root {
  --font-family-primary: Vaillant-Regular, sans-serif;
  --font-family-bold: Vaillant-Bold, sans-serif;
  --font-family-semiBold: Vaillant-Semibold, sans-serif;
  --font-family-medium: Vaillant-Regular, sans-serif;
  --font-family-regular: Vaillant-Regular, sans-serif;
  --font-family-light: Vaillant-Light, sans-serif;
}

[data-app="migo"]:root {
  --font-family-primary: Roboto-Medium, sans-serif;
  --font-family-bold: Roboto-Bold, sans-serif;
  --font-family-semiBold: Roboto-SemiBold, sans-serif;
  --font-family-medium: Roboto-Medium, sans-serif;
  --font-family-regular: Roboto-Regular, sans-serif;
  --font-family-light: Roboto-Light, sans-serif;
}

