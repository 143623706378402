
.icon-wrapper
  height: calc(50vh - 2rem)
  flex-direction: column
  align-items: center
  justify-content: center

.is-section-title-icon
  margin-right: 1rem

// #TODO: wait for component design
.temperature-wrapper
  margin: 1.5rem
  border-bottom: 2px solid var(--primary)
  align-self: center

  app-text
    line-height: 1.75rem

.button.is-primary-button[disabled]
  background-color: var(--primary)

.is-text
  line-height: $text-line-height
  margin-bottom: 21px
