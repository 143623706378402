@charset "utf-8";
/**
 *
 * Set specific variables or override Bulma variable (https://bulma.io/documentation/customize/variables/)
 */
@import "library/sources/sass/utils/utils";

// Import global variables
@import "library/sources/sass/_common-variables.sass";

// app specific variables
@import "./variables/_typography";
@import "./variables/_commonColor";
@import "./variables/_button";

@import "library/sources/sass/settings";
