@import 'library/sources/sass/variables/color';

:root {
  --page-loader-primary-color: var(--primary);
  --page-loader-secondary-color: var(--primary);
}

$theme-color: var(--primary) !important;
$primary: #00917E;
$grey-lighter: #f2f2f2;
$grey-light: #dddddd;
$grey-dark: #292929;
$loader-color: $theme-color;
$header-color: $grey-dark;
$switch-color: $theme-color;
$disabled-background: rgba(255, 255, 255, 0.3);
$secondary: $theme-color;
$body-background-color: #fafafa;
$header-background: $theme-color;
$header-color: white;

$lay-link-chevron-color: #d4d4d4;
$title-color: #292929;


/**
 * Panel Component
 * https://bulma.io/documentation/components/panel/#variables
 */
$panel-block-background: white;

$panel-block-color: $grey-dark;

$page-loader-primary-color: $theme-color;

$badge-bg-color: $theme-color;

$subtitle-color: #7f7f7f;
