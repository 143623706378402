.bg-grey-100 { background-color: var(--grey-100); transition: background-color 150ms ease-out; }
.bg-grey-90 { background-color: var(--grey-90); transition: background-color 150ms ease-out; }
.bg-grey-80 { background-color: var(--grey-80); transition: background-color 150ms ease-out; }
.bg-grey-70 { background-color: var(--grey-70); transition: background-color 150ms ease-out; }
.bg-grey-50 { background-color: var(--grey-50); transition: background-color 150ms ease-out; }
.bg-grey-30 { background-color: var(--grey-30); transition: background-color 150ms ease-out; }
.bg-grey-20 { background-color: var(--grey-20); transition: background-color 150ms ease-out; }
.bg-grey-10 { background-color: var(--grey-10); transition: background-color 150ms ease-out; }
.bg-grey-5 { background-color: var(--grey-5); transition: background-color 150ms ease-out; }
.bg-grey-0 { background-color: var(--grey-0); transition: background-color 150ms ease-out; }

.bg-blue { background-color: var(--blue); transition: background-color 150ms ease-out; }
.bg-blue-10 { background-color: var(--blue-10); transition: background-color 150ms ease-out; }

.bg-grey-hover-100:hover { background-color: var(--grey-100); }
.bg-grey-hover-90:hover { background-color: var(--grey-90); }
.bg-grey-hover-80:hover { background-color: var(--grey-80); }
.bg-grey-hover-70:hover { background-color: var(--grey-70); }
.bg-grey-hover-50:hover { background-color: var(--grey-50); }
.bg-grey-hover-30:hover { background-color: var(--grey-30); }
.bg-grey-hover-20:hover { background-color: var(--grey-20); }
.bg-grey-hover-10:hover { background-color: var(--grey-10); }
.bg-grey-hover-5:hover { background-color: var(--grey-5); }
.bg-grey-hover-0:hover { background-color: var(--grey-0); }

// @deprecated
.bg-hover-grey-0 { background-color: var(--grey-100-2); }