// common helpers
@import "./_common-helpers.sass";

@import "./helpers/all.scss";

// common global classes
@import './_common-styles.scss';

@import './variables/css-variables';

// global modifiers
@import "./modifiers.sass";

// Mobile modifiers
@import "./mobile.sass";

@import './schedule-quizz';

@import './rtl.sass';

.page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  z-index: 99;
}

.page-loader {
  width: 35px;
  height: 35px;
  border-color: rgba(0, 0, 0, .1);
  border-left-color: var(--primary);
  border-width: 3px;
  display: block;
  position: absolute;
  border-style: solid;
  border-radius: 100%;
  top: calc(50% - 35px / 2);
  left: calc(50% - 35px / 2);
  animation: spin 500ms infinite linear;
}
