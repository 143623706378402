:root {
    --grey-100:                 #000000;
    --grey-90:                  #03080F;
    --grey-80:                  #424B61;
    --grey-70:                  #68718C;
    --grey-50:                  #A1A9BF;
    --grey-30:                  #E0E3EB;
    --grey-20:                  #F0F1F5;
    --grey-10:                  #F4F5F7;
    --grey-5:                   #F4F5F7;
    --grey-0:                   #FFFFFF;

    --grey-100-2: rgba(0, 0, 0, .02);

    // Retro-compatibility with old colors
    --gs-black-space:           #000000;
    --gs-black-grey:            #03080F;
    --gs-dark-grey:             #424B61;
    --gs-medium-grey:           #68718C;
    --gs-grey:                  #A1A9BF;
    --gs-light-grey:            #E0E3EB;
    --gs-silver:                #F0F1F5;
    --gs-bright-silver:         #F4F5F7;
    --gs-white:                 #FFFFFF;

    // Retro-compatibility with old colors
    --black-space:                var(--gs-black-space);
    --black-grey:                 var(--gs-black-grey);
    --dark-grey:                  var(--gs-dark-grey);
    --medium-grey:                var(--gs-medium-grey);
    --grey:                       var(--gs-grey);
    --light-grey:                 var(--gs-light-grey);
    --silver:                     var(--gs-silver);
    --bright-silver:              var(--gs-bright-silver);
    --white:                      var(--gs-white);

    --blue-lightmode: #4078f8;
    --blue-lightmode-5: rgba(64, 120, 248, 0.05);
    --blue-lightmode-10: rgba(64, 120, 248, 0.1);
    --blue-lightmode-15: rgba(64, 120, 248, 0.15);
    --blue-darkmode: #5084f8;

    --body-background-color: var(--white);
    --content-background-color: var(--bright-silver);
    --bright-blue-grey: #F6F7F9;

    --primary: var(--blue);
    --blue: var(--blue-lightmode);
    --blue-5: var(--blue-lightmode-5);
    --blue-10: var(--blue-lightmode-10);
    --blue-15: var(--blue-lightmode-15);

    --black-grey-40: rgba(41, 41, 41, 0.4);
    --grey-15: rgba(195, 195, 195, 0.15);
    --white-30: rgba(255, 255, 255, 0.3);

    --chart-header-bg-color: #1a4ec4;
    --chart-bar-color-blurred: #e2eafd;
    --chart-offpeak-bar-color: #bfd1f8;
    --chart-offpeak-bar-color-blurred: #f5f8fd;
    --chart-hot-water-bar-color-blurred: #ffe7d8;

    --netatmo-orange: #ff8500;
    --netatmo-orange-transparent: rgba(255, 133, 0, 0.1);
    --netatmo-orange-5: rgba(255, 133, 0, 0.05);
    --netatmo-orange-10: rgba(255, 133, 0, 0.1);

    --switch-color: var(--secondary);

    --separation-border: 1px solid #ECECEC;

    // Color palette 2 : https://app.zeplin.io/project/5c7fd8b2ede03b34df474bc1/screen/5dd669514b0e46bbcd7fa558
    --green-confirmation: #2DD160;
    --green-confirmation-opacity: .1;
    --green-confirmation-transparent: rgba(45, 209, 96, 0.1);

    --blue-opacity: 0.1;
    --red-alert: #FF4038;
    --red-alert-opacity: .10;
    --red-alert-transparent: rgba(255, 64, 56, 0.1);
    --orange-alert: #FF7A0E;
    --orange-alert-opacity: .1;
    --orange-alert-transparent: rgba(255, 122, 14, 0.1);

    // Color palette 3 : https://app.zeplin.io/project/5c7fd8b2ede03b34df474bc1/screen/5dd66951669af1bc8180676a
    --yellow: #FDC608;
    --yellow-opacity: .16;
    --yellow-transparent: rgba(253, 198, 8, 0.16);

    --sky-blue: #79ADD5;
    --slight-blue: #C9D7EC;
    --slight-blue-25: rgba(201, 215, 236, .25);

    // Color palette 4 : https://app.zeplin.io/project/5c7fd8b2ede03b34df474bc1/screen/6080404f11b14018207288e3
    --cornflower-blue: #7C87FF;
    --cornflower-blue-opacity: .1;
    --cornflower-blue-transparent: rgba(124, 135, 255, 0.1);

    --arctic-blue: #5AB8FF;
    --arctic-blue-opacity: .1;
    --arctic-blue-transparent: rgba(90, 184, 255, 0.1);

    --see-green: #7aceba;
    --see-green-transparent: rgba(122, 206, 186, 0.1);

    --olive-green: #a2ce7a;
    --olive-green-transparent: rgba(162, 206, 122, 0.1);

    --eco-green: #88ce7a;
    --eco-green-blurred: rgba(136, 206, 122, 0.3);
    --eco-green-transparent: rgba(136, 206, 122, 0.1);

    --greeny-blue: #40A497;
    --greeny-blue-blurred: rgba(64, 164, 151, 0.3);
    --greeny-blue-transparent: rgba(64, 164, 151, 0.1);

    --salmon-pink: #FFAD7C;
    --salmon-pink-opacity: .1;
    --salmon-pink-transparent: rgba(255, 173, 124, 0.1);

    --ruby-pink: #ff6865;
    --ruby-pink-transparent: rgba(255, 104, 101, 0.1);

    --amnesty-purple: #c67cf8;
    --amnesty-purple-transparent: rgba(198, 124, 248, 0.1);


    // Color palette 5 : https://app.zeplin.io/project/5c7fd8b2ede03b34df474bc1/screen/6080404f40f9bc1ffeaa6f52
    --coral-pink: #F18B6E;

    // Color palette security : https://app.zeplin.io/project/58e36a652fa14d37a4d06519/screen/60a40758193d902b13865dc6
    --security-grey-background: var(--black-grey);
    --security-grey-background-opacity: .06;
}

[data-theme="dark"] {
    --grey-100:                   #FFFFFF;
    --grey-90:                    #F9F9FB;
    --grey-80:                    #BDC5DB;
    --grey-70:                    #A7B0CC;
    --grey-50:                    #778199;
    --grey-30:                    #3A404D;
    --grey-20:                    #2A2D36;
    --grey-10:                    #212329;
    --grey-5:                     #16181C;
    --grey-0:                     #0A0C0F;

    --grey-100-2: rgba(255, 255, 255, .02);

    // Retro-compatibility with old colors
    --black-space:                var(--grey-100);
    --black-grey:                 var(--grey-90);
    --dark-grey:                  var(--grey-80);
    --medium-grey:                var(--grey-70);
    --grey:                       var(--grey-50);
    --light-grey:                 var(--grey-30);
    --silver:                     var(--grey-20);
    --bright-silver:              var(--grey-10);
    --white:                      var(--grey-0);

    --body-background-color: var(--white);
    --content-background-color: black;
    --bright-blue-grey: #0F0F0F;

    --primary: var(--blue);
    --blue: var(--blue-darkmode);
    --blue-5: rgba(80, 132, 248, 0.05);
    --blue-10: rgba(80, 132, 248, 0.1);
    --blue-15: rgba(80, 132, 248, 0.15);
    --blue-20: rgba(80, 132, 248, 0.2);

    --black-grey-40: rgba(244, 244, 244, 0.4);
    --grey-15: rgba(195, 195, 195, 0.15);
    --white-30: rgba(24, 24, 24, 0.3);

    --chart-header-bg-color: #1949b7;
    --chart-bar-color-blurred: #29385b;
    --chart-offpeak-bar-color: #1443ad;
    --chart-offpeak-bar-color-blurred: #172545;
    --chart-hot-water-bar-color-blurred: #5d4639;

    --netatmo-orange: #ff8f15;
    --netatmo-orange-transparent: rgba(255, 133, 0, 0.1);

    --separation-border: 1px solid #3C3C3C;

    // Color palette security : https://app.zeplin.io/project/58e36a652fa14d37a4d06519/screen/60a40758193d902b13865dc6
    --security-grey-background: var(--bright-silver);
    --security-grey-background-opacity: .06;

    // Color palette 2 : https://app.zeplin.io/project/5c7fd8b2ede03b34df474bc1/screen/5dd669514b0e46bbcd7fa558
    --green-confirmation: #10D868;
    --green-confirmation-opacity: .2;
    --green-confirmation-transparent: rgba(16, 216, 104, 0.2);
    --blue-opacity: 0.2;
    --red-alert: #FF4C40;
    --red-alert-opacity: .20;
    --red-alert-transparent: rgba(255, 76, 64, 0.2);
    --orange-alert: #FF8820;
    --orange-alert-opacity: .2;
    --orange-alert-transparent: rgba(255, 136, 32, 0.2);

    // Color palette 3 : https://app.zeplin.io/project/5c7fd8b2ede03b34df474bc1/screen/5dd66951669af1bc8180676a
    --yellow: #FCCE2C;
    --yellow-opacity: .2;
    --yellow-transparent: rgba(252, 206, 44, 0.2);

    --sky-blue: #82B2D6;
    --slight-blue: #C4D0E4;
    --slight-blue-25: rgba(196, 208, 228, .25)

    // Color palette 4 https://app.zeplin.io/project/5c7fd8b2ede03b34df474bc1/screen/6080404f11b14018207288e3
    --cornflower-blue: #868FFF;
    --cornflower-blue-opacity: .2;
    --cornflower-blue-transparent: rgba(124, 135, 255, 0.1);

    --arctic-blue: #48B0FF;
    --arctic-blue-opacity: .2;
    --arctic-blue-transparent: rgba(90, 184, 255, 0.1);

    --see-green: #86d6c2;
    --see-green-transparent: rgba(122, 206, 186, 0.1);

    --olive-green: #acd884;
    --olive-green-transparent: rgba(162, 206, 122, 0.1);

    --eco-green: #acd884;
    --eco-green-blurred: rgba(172, 216, 132, 0.3);
    --eco-green-transparent: rgba(172, 216, 132, 0.1);

    --greeny-blue: #368e83;
    --greeny-blue-blurred: rgba(54, 142, 131, 0.3);
    --greeny-blue-transparent: rgba(54, 142, 131, 0.1);

    --salmon-pink: #FFB588;
    --salmon-pink-opacity: .2;
    --salmon-pink-transparent: rgba(255, 173, 124, 0.1);

    --ruby-pink: #ff7673;
    --ruby-pink-transparent: rgba(255, 104, 101, 0.1);

    --amnesty-purple: #ca86f8;
    --amnesty-purple-transparent: rgba(198, 124, 248, 0.1);

    // Color palette 5 : https://app.zeplin.io/project/5c7fd8b2ede03b34df474bc1/screen/6080404f40f9bc1ffeaa6f52
    --coral-pink: #F69074;

    // Color palette security : https://app.zeplin.io/project/58e36a652fa14d37a4d06519/screen/60a40758193d902b13865dc6
    --security-grey-background: var(--black-space);
    --security-grey-background-opacity: .16;
}

