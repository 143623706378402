$font-path: "./webfonts/" !default

@font-face
  font-family: "Vaillant-Bold"
  src: url("#{$font-path}Vaillant16-Heavy.ttf") format("truetype")

@font-face
    font-family: "Vaillant-Semibold"
    src: url("#{$font-path}Vaillant16-Demi.ttf") format("truetype")

@font-face
    font-family: "Vaillant-Regular"
    src: url("#{$font-path}Vaillant16-Book.ttf") format("truetype")

@font-face
    font-family: "Vaillant-Light"
    src: url("#{$font-path}Vaillant16-Light.ttf") format("truetype")

$font-bold: "Vaillant-Bold"
$font-semiBold: "Vaillant-Semibold"
$font-medium: "Vaillant-Regular"
$font-regular: "Vaillant-Regular"
$font-light: "Vaillant-Light"
$font-thin: "Vaillant-Light"
